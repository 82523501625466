<template>
    <div class="h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
         style="height: 100vh; overflow: hidden">
        <div class="h-100 h-100 flex flex-column">
            <div class="flex h-100">
                <contract-side-menu :active-index="4"></contract-side-menu>
                <div class="right-panel">

                    <MetaRightSideHeader>
                      <template #title>
                        动态
                      </template>
                    </MetaRightSideHeader>
                    <div id="dody" class="custom-scroller" v-infinite-scroll="getNewActivities" style="overflow: auto">
                        <el-timeline style="padding-left: 0px;margin-top: 10px;margin-right: 5px;">
                            <el-timeline-item
                                    v-for="(actiivty , index) in activitiesMessages"
                                    :key="index"
                                    style="padding-left: 0px"

                            >
                                <div style="margin-bottom: 2rem">
                                    <span class="data-stamp">{{Dataformat(actiivty.createTime)}}</span>
                                </div>
                                <el-card
                                    style="margin-bottom: 1rem"
                                    v-for="inserActivity in actiivty.auditingItems"
                                    class="dody-data-content"
                                    @click="skipContract(inserActivity)"
                                >
                                    <span class="time-stamp">{{HourMinuteformat(inserActivity.createTime)}}</span>
                                    <i aria-hidden="true" class="material-icons v-mid theicon" :class="setIconByType(inserActivity.iconType)">
                                    </i>
                                    <div class="v-mid" style="margin-left: 1.4rem;font-size: 1.2rem;">
                                        <user-icon v-if="inserActivity.userName?.length" :url="inserActivity.headImgUrl" :name="inserActivity.userName" :uid="inserActivity.userName" :size="38"></user-icon>
                                        <span v-else class="holder" style="margin-left: var(--userIconSize)"></span>
                                    </div>
                                    <span class="messageI">{{inserActivity.messageInfo}}</span>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getActivity,
    } from "../../api/api";
    import ContractSideMenu from "../../components/ContractSideMenu";
    import {MetaMessage} from "../../util";
    import MetaRightSideHeader from "../../components/universalUI/MetaRightSideHeader";
    import UserIcon from "../../components/UserIcon";
    import {MessagesInfoType, SourceType} from "../../constant/messages/MessagesInfoType";

    export default {
        name: "ContractMessages",
        components: {UserIcon, MetaRightSideHeader, ContractSideMenu},
        data() {
            return {
                relationHeight: document.body.clientHeight-100,
                metaMessage: new MetaMessage(),
                activitiesMessages: [],
                pageSize: 10,
                pageIndex: -1,
                endflag: false,
            }
        },
        mounted() {
            //屏幕设置为175%后,无线滚动出发事件失效,需自己监听滚动条
            this.$nextTick(() => {
                // 进入nexTick
                var bady = document.getElementById("dody");   // 获取滚动条的dom
                bady.onscroll = () => {
                    // 获取距离顶部的距离
                    var scrollTop = bady.scrollTop;
                    // 获取可视区的高度
                    var windowHeight = bady.clientHeight;
                    // 获取滚动条的总高度
                    var scrollHeight = bady.scrollHeight;
                    // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
                    // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
                    if(scrollTop+windowHeight+1>=scrollHeight){
                        this.getNewActivities();
                    }
                }
            });
        },
        methods: {
            getNewActivities :function(){
                if (!this.endflag){
                    this.pageIndex += 1
                }else {
                    return;
                }
                getActivity(this.pageIndex,this.pageSize).then(res => {
                    if (res.data.code == 0){
                        if (this.activitiesMessages.length == 0){
                            this.activitiesMessages = res.data.data
                        }else {
                            for (const m in res.data.data){
                                if (m == 0 && this.activitiesMessages[this.activitiesMessages.length-1].createTime == res.data.data[m].createTime) {
                                    for (const xx in res.data.data[m].auditingItems){
                                        this.activitiesMessages[this.activitiesMessages.length-1].auditingItems.push(res.data.data[m].auditingItems[xx])
                                    }
                                }
                                else{
                                    this.activitiesMessages.push(res.data.data[m])
                                }
                            }
                        }
                    }else{
                        this.endflag = true
                    }
                }).catch(e => {
                    console.log(e);
                    this.metaMessage['error']('查询数据失败');
                });
            },
            Dataformat :function(data){
                const dt = new Date(data)
                const y = dt.getFullYear()
                const m = (dt.getMonth() + 1 + '').padStart(2,0)
                const d = (dt.getDate() + '').padStart(2,0)
                return m+"/"+d+"/"+y;
            },
            HourMinuteformat :function (data) {
                let str = data;
                return str.substr(11,5);
            },
            setIconByType(iconType){
                switch (iconType) {
                    case 1:
                        return 'plusIcon';
                    case 2:
                        return 'checkIcon';
                    case 3:
                        return 'initiateIcon';
                    case 4:
                        return 'closeIcon';
                    case 5:
                        return 'addIcon';
                    case 6:
                        return 'penIcon';
                    case 7:
                        return 'eyesIcon';
                }
            },
            skipContract(row){
                if (row.sourceType === SourceType.CONTRACT) {
                    let routerUrl = this.$router.resolve({name: 'contractView', query: {id: row.documentId}});
                    window.open(routerUrl.href,'_blank')
                } else if (row.sourceType === SourceType.PUBLISHED_TEMPLATE) {
                    let routerUrl = this.$router.resolve({name: 'templatePublish', query: {templateId: row.documentId}});
                    window.open(routerUrl.href,'_blank')
                } else if(row.sourceType === SourceType.PRO){
                    let routerUrl = this.$router.resolve({name: 'doc', query: {fileId: row.documentId}});
                    window.open(routerUrl.href,'_blank')
                }else{
                    //不跳转
                }
            },
        }
    }
</script>

<style scoped>
    .v-mid{
      margin-left: 1.2rem;font-size: 1.2rem;
      display: inline-block;
    }
    ::v-deep .el-timeline-item__tail{
        position: absolute;
        left: 50px;
        border-left: 3px solid #cccdcf;
    }
    ::v-deep .el-timeline-item__node {
        left: 50px;
    }
    ::v-deep .el-timeline-item__wrapper{
        position: relative;
        padding-left: 5px;
        top: -5px;
    }
    ::v-deep .el-timeline .el-timeline-item:last-child .el-timeline-item__tail{
        display: block;
        height: calc( 100% - 50px);
    }

    .el-timeline-item:first-child .data-stamp{
        background-color: #2a80ff;
    }
    .data-stamp{
        margin-top:0;
        color: whitesmoke;
        /*font-weight: bold;*/
        background-color: #999;
        font-size: 15px;
        padding: 5px 10px;
        border-radius: 1rem;
    }

    .time-stamp{
        margin-left: 0.55rem;
        color: #777;
        font-size: 0.98rem;
    }

    .theicon{
        border-radius: 50%;
        border: 2px solid #DDD;
        padding: 5px;
        font-size: 1rem;
        color: #DDD;
        height: 16px;
        width: 16px;
    }

    .messageI{
        margin-left: 1.4rem;
        font-size: 0.9rem;
    }

    .useravatar{
        text-align: center;
        margin-top: 0.5rem;
        font-size: 0.5rem
    }

    .right-panel {
        text-align: left;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        height: 100%;
        width: calc(100% - 283px);
    }


    .tool-btns .btn-img-wrapper:not(:last-child) {
        margin-right: 16px;
    }

    .detail-row .icon {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 15px;
        line-height: 16px;
        font-size: 12px;
        flex: none;
        color: #8f959e;
        align-self: flex-start;
        cursor: default;
    }

    .meta-dialog .event-edit-dialog-header {
        padding: 0;
        background-image: linear-gradient(to right bottom, rgb(78, 131, 253), rgb(51, 112, 255));
    }

    .eyesIcon{
        background : url(../../assets/images/eyes.png) no-repeat center;
        background-size: 50% 50%;
    }
    .penIcon{
        background : url(../../assets/images/pen.png) no-repeat center;
        background-size: 50% 50%;
    }
    .initiateIcon{
        background : url(../../assets/images/initiate_approval.png) no-repeat center;
        background-size: 50% 50%;
    }
    .checkIcon{
        background : url(../../assets/images/check.png) no-repeat center;
        background-size: 50% 50%;
    }
    .plusIcon{
        background : url(../../assets/images/plus.png) no-repeat center;
        background-size: 50% 50%;
    }
    .addIcon{
        background : url(../../assets/images/add_team.png) no-repeat center;
        background-size: 50% 50%;
    }
    .closeIcon{
        background : url(../../assets/images/close.png) no-repeat center;
        background-size: 50% 50%;
    }
    .dody-data-content >>> .el-card__body{
      display: flex;
      align-items: center;
    }
    .dody-data-content .time-stamp,
    .dody-data-content .v-mid{
      flex-shrink: 0;
    }
    .dody-data-content .messageI{

    }
</style>
<style>

    .el-table .warning-row {
        background-color: #e31f1f14;
    }

    .el-table .success-row {
        background-color: #f0f9eb;
    }

</style>
